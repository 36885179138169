/// Libraries
//import { APP_URL, API_URL, API_URL_TEST }  from '@env';
import React, { useState, useEffect, useContext, useMemo, useCallback, useRef, createRef } from 'react';
import { View, Text, FlatList, TouchableOpacity,Pressable, Image, RefreshControl, StyleSheet, Dimensions, TextInput, TouchableWithoutFeedback, Modal } from "react-native";
import * as Linking from 'expo-linking';
import * as WebBrowser from 'expo-web-browser';
import { FontAwesome5, MaterialCommunityIcons } from '@expo/vector-icons';
import { useHover, useFocus, useActive } from 'react-native-web-hooks';

// Styling 
import FncColors from '../styles/colors';
let Colors = [];
import { CreateResponsiveStyle, DEVICE_SIZES, minSize, maxSize } from 'rn-responsive-styles';

// Functions
import { adjustUri, textReplace, customConsoleLog, openUrlNewTab, sendGa, sendGaButton, emailCheck } from '../functions/common';
import { get_script, run_script, get_verfijnen, refresh_grafieken } from '../functions/adviezen';

// Context
import { GlobalDispatcher, GlobalContext } from '../context/globalState';
import { SET_PARAM, RESET_PARAM, SET_ADVIESSTAP_ID, SET_FIRST_VERFIJNSTAP_ID } from '../context/actions/commonActions';


// Components
import IconSetter from './iconSetter';
import Loader from '../components/loader';
import { Button, ButtonVerfijn } from './button';
import OuterContainer from '../components/outerContainer'
import MainContainer from '../components/mainContainer'
import TopContainer from '../components/topContainer'
import PostContainer from '../components/postContainer'
import BottomContainer from '../components/bottomContainer'
import FooterFrame from '../components/footerFrame';
import Footer from '../components/footer';
import { P, H1, H2, H3, H4, Space, PBold } from '../components/text';
import SocialButton from '../components/socialButton';
import Message from '../components/message';


// Variables

var status = 0;

var googleDone = false;

// Default function
export default function AdviesContainer(props) {
  Colors = FncColors();
  const Logo = require('../assets/images/' + global.settings.image_folder + '/logo.png');
  const { styles } = useStyles();

  //Context
  const commonDispatch = useContext(GlobalDispatcher).commonDispatch;
  const commonContext = useContext(GlobalContext).commonState;
  const debug = commonContext.debug;
  const param = commonContext.param;

  const refMail = createRef(null);
  const isHoveredMail = useHover(refMail);

  const [disableHover, setDisableHover] = useState(false);
  const [alertOverlay, setAlertOverlay] = useState(false);

  useEffect(() => {
    if(navigator.userAgent.indexOf("Firefox") != -1 ){
      setDisableHover(true);
    }
  },[])


  const refVorige = createRef(null);
  const isHoveredVorige = useHover(refVorige);
  const refOpnieuw = createRef(null);
  const isHoveredOpnieuw = useHover(refOpnieuw);

  //Props
  const aom_data = props.aom_data;
  const mobile = props.mobile;
  const title = props.title;
  const subtitle = props.subtitle;
  const module_naam = props.module || "onbekende_module";

  //moet tzt uit de API komen
  const socials = {
    titel: "Advies bewaren of delen",
    tekst: "<p>Je kunt het overzicht naar je zelf mailen. Gebruik daarvoor de onderstaande knop.</p"
  }

  //Stap
  const aom_module = aom_data.module;
  const stap = aom_data.stap;
  const adviezen = stap.adviezen;
  const verfijningen = stap.verfijningen;
  const socialButtons = aom_data.socials.buttons;

  //Refs

  //States
  const [isModalVisible, setModalVisible] = useState(false);
  const [isTekstVisibleSocial, setTekstVisibleSocial] = useState(true);
  const [htmlOverlay, setHtmlOverlay] = useState(false);
  const [html, setHtml] = useState('');
  const [verfijnAnimatie, setVerfijnAnimatie] = useState(false);
  const [email, setEmail] = useState('');
  const mailButtonCaption = 'Advies bewaren';

  // uitgangspositie zichtbaarheid adviezen
  // verzamelen en in state zetten
  const tmp = [];
  adviezen.map((data, index) => {
    tmp.push({
      "zichtbaarheid": data.zichtbaarheid,
      "accordeon_id": data.accordeon_id,
      "accordeon_position": data.accordeon_position,
      "accordeon_multi_open": data.accordeon_multi_open,
      "open": ( data.zichtbaarheid == 1 || data.zichtbaarheid == 2 || ( data.zichtbaarheid == 4 && data.accordeon_open == 1 ) ) ? 1 : 0
    })
    }
  );
  const [ adviesZichtbaar, setAdviesZichtbaar ] = useState( tmp );

  // zichtbaarheid van aangeklikte onderdeel aanpassen
  const setAdviesZichtbaarHelper = ( index ) => {
    // state overnemen
    const tmp = [...adviesZichtbaar];
    const adv = tmp[ index ];

    // als het een accordeon betreft en er mogen geen
    // meerdere elementen tegelijk open zijn
    // dan alle elementen, behalve de aangeklikte sluiten
    if ( adv.zichtbaarheid == 4 && !adv.accordeon_multi_open ) {
      for ( var i = 0; i < tmp.length; i++ ){
        if (adv.accordeon_id == tmp[i].accordeon_id ){
          if( i != index ) tmp[i].open = 0;
        }
      }
    }

    // nu de status van het aangeklikte element
    // inverteren
    adv.open = 1 - adv.open;
    tmp[ index ] = adv;

    // en terugzetten in de state
    // met een rerender als gevolg
    setAdviesZichtbaar( tmp );
  }



  let fontAfwijking = false;
  if (global.settings.font_regular !== 'ProximaNova-Regular.otf') {
    fontAfwijking = true;
  }

  const prevScreen = () => {
    var l = param.chain.length;

    //terug naar het startscherm
    if (l == 0) {
      commonDispatch({ type: RESET_PARAM });
    }
    else {
      param.goto = param.chain[l - 1].stap_id;
      param.chain.pop();
      commonDispatch({ type: SET_PARAM, value: param });
    }

    //Doe er een timeout van 1 miliseconde omheen zodat hij het doet nadat je geredirect bent
    setTimeout(function(){
      //refresh_grafieken();
    }, 1);

    //navigation.push( "Main", {module: module.mod_naam });
    props.navigation.reset({
      index: 0,
      routes: [{ name: "Main", params: { module: module_naam } }],
    });
  }

  const mailAdvies = () => {
    if (!emailCheck( email )){
      setModalVisible(false);
      setAlertOverlay( {title: 'Foutmelding', message: 'Er was geen geldig e-mailadres ingevuld.' } );
      return;
    }

    const domein = global.settings.app_url;
    const json = {
      "goto": param.goto,
      "chain": param.chain,
      "email": email,
      "module": module_naam,
      "domein": domein
    };
    //console.log(JSON.stringify(json));

    const url = textReplace('[api_url]/mail/advies?code=' + global.settings.api_key + '&json=' + encodeURIComponent(JSON.stringify(json)), [global.settings.api_url]);
    //console.log(url);
    fetch(url, { method: 'POST' })
      .then(response => response.json())
      .then(json => {
        //Controleren op error
        if (json.success == false) {
          setModalVisible(false);
          setAlertOverlay( {title: 'Foutmelding', message: 'Er ging iets fout, probeer het later nog eens.' } );
          //setError(json.error);
          if (debug) console.log('error: ' + json.error);
          //setLoading(false);
        }
        else {
          setModalVisible(false);
          setAlertOverlay( {title: 'Melding', message: 'De email is verstuurd.' } );
          //setError('');

          //Data ontleden
          //aom_data = json.data;
          //console.log(aom_data);

          //setLoading(false);
        }
      }
      )
      .catch((error) => {
        setModalVisible(false);
        setAlertOverlay( {title: 'Foutmelding', message: 'Er ging iets fout, probeer het later nog eens.' } );
        //setError("Error in api");
        if (debug) console.log('error:' + error);
        //setLoading(false);
      })
  }

  const clickHandler = (e) => {
    const el = e.target.closest("A");
    if (el && e.currentTarget.contains(el)) {
      // als class gtm aanwezig, dan titel sturen naar Google Analytics
      if ( el.classList.contains('gtm') ){
        sendGaButton( module_naam,  el.getAttribute( 'data-title' ) );
      }

      // als het geen javascript functie is, dan afbreken
      if (!el.href.includes('javascript')) return;

      //default acties uitzetten
      e.preventDefault();
      e.returnValue = false;

      //functie uitrafelen
      let href = el.href.replace('javascript:', '');
      const fnc = href.split('(')[0];
      switch (fnc) {
        case 'navigate':
          prevScreen();
          break;
        case 'toggle_grafiek_vliegen':
          toggle_grafiek_vliegen();
          break;
        case 'show_overlay_html':
          //Split alleen op de 1e (
          //href = href.replace(/'(.)/mg, "$1");//quotes eruit halen
          href = href.replace(/'/g, "%27");//quotes eruit halen
          const tmp = href.split('(').slice(1).join('(');

          //Vind de index van de laatste haak en pak vervolgens daarop de substring
          const lastIndex = tmp.lastIndexOf(')');
          let par = tmp.substr(0, lastIndex)
          par = par.replace(/\\(.)/mg, "$1");//Slashes eruit halen

          //Haal de titel eruit door te splitten op de 1e comma
          const titel = par.split(/,/)[0].replace(/%27/g,'').replace(/`/g, '');
          //Haal de tekst eruit door te splitten op de comma, de eerste eraf te slicen en daarna weer aan elkaar plakken
          const tekst = par.split(',').slice(1).join(',').replaceAll('%E2%82%AC', '€');

          //Doe alles bij elkaar en zet h2 om de titel heen
          let total_tekst = '';
          try {
            total_tekst = "<h2>" + titel + "</h2>" + decodeURI(tekst).replace(/`/g, '').replace(/^'/, '').replace(/'*$/, '');//Verwijder de 1ste en laatste hoge komma
          } catch (error){
            if (error instanceof URIError) {
              console.log('URIError:', error.message);
              total_tekst = "<h2>" + titel + "</h2>" + unescape(tekst).replace(/`/g, '').replace(/^'/, '').replace(/'*$/, '');//Verwijder de 1ste en laatste hoge komma
            } else {
              console.log('Error:', error.message);
            }          
          }
          show_overlay_html(total_tekst);
          break;

        default:
          setModalVisible(false);
          setAlertOverlay( {title: 'Foutmelding', message: 'Onbekende functie: ' + fnc } );
      }
      return false;
    }
  }


  const show_overlay_html = (tekst) => {
    setHtmlOverlay(true);
    setHtml(tekst);
  }

  const toggle_grafiek_vliegen = () => {
    var g1 = 'cls_grafiek_uitstoot_totaal';
    var g2 = 'cls_grafiek_uitstoot_totaal_vliegen';
    var a = 'toggle_grafiek_vliegen';

    //huidige anchor tekst ophalen
    var anchors = document.getElementsByClassName(a);
    var anchor = anchors[anchors.length - 1];
    var txt = anchor.innerHTML;
    var alt = anchor.dataset.tekst;

    // als het advies opnieuw getoond wordt, dan staan alle grafieken er dubbel in!!
    // daarom pak ik het laatste element
    var objs1 = document.getElementsByClassName(g1);
    var obj1 = objs1[objs1.length - 1];
    var objs2 = document.getElementsByClassName(g2);
    var obj2 = objs2[objs2.length - 1];

    if (obj1.style.display !== "none") {
      obj1.style.display = "none";
      obj2.style.display = "block";
    }
    else {
      obj1.style.display = "block";
      obj2.style.display = "none";
    }

    //teksten swappen
    anchor.innerHTML = alt;
    anchor.dataset.tekst = txt;

    refresh_grafieken();
  }

  const runVerfijn = (props, stap_id) => {
    let chain = commonContext.param.chain;
    //console.log(chain);
    // newParam.push({stap_id: 1171});

    let objectIndex = chain.findIndex((obj => obj.stap_id == stap_id));

    //Zo lang als de volgende stap van de verfijning word gevonden, de chain verder legen zodat je weer opnieuw de verfijning kan toevoegen
    //console.log(objectIndex);
    if(objectIndex != '-1'){
      do {
        const next_stap_id = chain[objectIndex].vervolgstapId;
        //console.log(next_stap_id);
        chain.splice(objectIndex, 1);
        //console.log(chain);
        objectIndex = chain.findIndex((obj => obj.stap_id == next_stap_id));
        //console.log(objectIndex);
      }
      while (objectIndex != '-1')



    }

    const param = {
      "goto": stap_id,
      "chain": chain
    };

    //Doe er een timeout van 1 miliseconde omheen zodat hij het doet nadat je geredirect bent
    setTimeout(function () {
      //setVerfijnAnimatie(false);
    }, 1)

    if (debug) console.log("Verfijn met: " + JSON.stringify(param));

    commonDispatch({ type: SET_FIRST_VERFIJNSTAP_ID, value: stap_id });
    commonDispatch({ type: SET_ADVIESSTAP_ID, value: stap.sta_id });
    commonDispatch({ type: SET_PARAM, value: param });
    props.navigation.reset({
      index: 0,
      routes: [{ name: "Main", params: { module: module_naam } }],
    });
  }

  const opnieuwInvullen = () => {
    commonDispatch({ type: RESET_PARAM });
    location.reload();

    /*props.navigation.reset({
      index: 0,
      routes: [{ name: "Main", params: { module: module_naam } }],
    });*/
  }

  const setFalse = () => {
    //setVerfijnAnimatie(false);
  }

  addEventListener('pageshow', setFalse);
    
  if (!googleDone) {
    googleDone = true;
    sendGa(module_naam, stap.pagina_tag);

    // console.log('google analytics advies');
  }

  const handleEscKey = useCallback((event) => {
    if (event.key === 'Escape') {
      setHtmlOverlay(false);
      setHtml(false);
      setModalVisible(false);
    }
  }, []);
 
  useEffect(() => {
    const beforeUnloadHandler = (event) => {
      // Recommended
      event.preventDefault();


      // Included for legacy support, e.g. Chrome/Edge < 119
      event.returnValue = '';
    };

    
    document.addEventListener('keyup', handleEscKey, false);
 
    window.addEventListener("beforeunload", beforeUnloadHandler);
    
    return () => {
      document.removeEventListener('keyup', handleEscKey, false);
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    };
  }, [handleEscKey]);

  return (
    <View style={verfijnAnimatie ? { backgroundColor: 'white', top: -90, position: 'absolute', minWidth: '100%' } : {zIndex: -1}}>
      <View style={{ opacity: (verfijnAnimatie ? 0 : 1), minHeight: Dimensions.get('window').height}}>
        <Modal 
          animationType={global.settings.overlay_animation || "slide"}
          visible={htmlOverlay}
          transparent={true}
        >
          <View accessible={true} style={styler.htmlOverlay}>
            <Button arrowIcon={false} color={Colors[7]} bottom={0} square={global.settings.button_border_radius === 0 ? true : false} fontSize={17} fontFamily={'Semibold'} textColor={Colors[9]} height={46} iconOrientation='none' caption={'Sluiten'} onPress={() => setHtmlOverlay(false)} />
             <P><div style={{ fontFamily: "Regular", fontSize: 18, lineHeight: '30px' }} dangerouslySetInnerHTML={{ __html: html }} /></P>
            <Button arrowIcon={false} color={Colors[7]} bottom={0} square={global.settings.button_border_radius === 0 ? true : false} fontSize={17} fontFamily={'Semibold'} textColor={Colors[9]} height={46} iconOrientation='none' caption={'Sluiten'} onPress={() => setHtmlOverlay(false)} />
          </View>
        </Modal>
        <Message
          visible={alertOverlay}
          content={alertOverlay}
          onPress={ () => { setAlertOverlay(false); }}
        />

        {
          adviezen.map((data, index) => {
            var tmp = get_script(data.tekst);
            // bij klimaatwijsopreis staat verfijnen in de adviestekst
            // later is dit een apart onderdeel geworden
            // als dit bij klimaatwijsopreis er uit gehaald wordt,
            // dan is dit deel overbodig en kun je gewoon de const 'verfijningen' gebruiken
            let { tekst, verfijnen } = get_verfijnen(tmp, verfijningen, index);    
            let toelichting = get_script(data.toelichting);
            if(module_naam === 'Vergelijk_mijn_energiegebruik' && mobile){
              let newToelichting = toelichting.replace(' ', ' style="width: 98%;" ');
              console.log(newToelichting);
              toelichting = newToelichting;
            }
            const zichtbaarheid = data.zichtbaarheid;
            const advies_class = data.class;

            try {
              toelichting = decodeURI(toelichting);//Decoden wanneer mogelijk
            } catch (error){
              if (error instanceof URIError) {
                console.log('URIError:', error.message);

              } else {
                console.log('Error:', error.message);
              }          
            }

            try {
              tekst = decodeURI(tekst);//Decoden wanneer mogelijk
            } catch (error){
              if (error instanceof URIError) {
                console.log('URIError:', error.message);

              } else {
                console.log('Error:', error.message);
              }          
            }

            status = 0;
            if (index == 0) {
              verfijnen.map((verfijn, i) => {
                // correctie voor KoV, die heeft geen display
                if(!verfijn.hasOwnProperty('display')) verfijn.display = true;

                if (verfijn.status == 1) status = 1;
              })
            }

            console.log(data.titel);
            let titel = "";
            if(index == 0){
              titel = "<h1 class='h1_advies' style='margin-top: 0px; margin-bottom: 0px;'>" + data.titel + "</h1>";
            } else {
              titel = "<h2 class='h2_advies' style='margin-top: 0px; margin-bottom: 0px; max-width: 85%;'>" + data.titel + "</h2>";
            }
            return (
              <View key={index} style={[styler.themeBody, styler.vgMaxBreedte]}>
                {status == 1 && (aom_module.mod_verfijning_titel || aom_module.mod_verfijning_subtitel) ?
                  <View accessible={true} style={styler.advies}>
                    <View style={[styler.kader, { padding: 10 } ]}>
                      <H3 accessible={true} alignSelf={"center"} fontFamily={"Regular"}>{aom_module.mod_verfijning_titel}</H3>
                      <P  alignSelf={"center"}>{aom_module.mod_verfijning_subtitel}</P>
                    </View>
                  </View>
                : null
                }

                <View style={zichtbaarheid != 1 ? zichtbaarheid == 4 ? [styler[ 'accordeon_' +  data.accordeon_position], styler['adv_' + advies_class]] : [styler.kader, styler['adv_' + advies_class]] : null}>
                  <View style={styler.advies}>
                    <View 
                      accessible={true}
                      style={[styler.advTitelTekst, !adviesZichtbaar[index].open ? (mobile ? { paddingTop: 10, paddingBottom: 15} : { paddingBottom: 20 }) : mobile ? { paddingTop: 10 } : null]}
                    >
                      <div style={{ fontFamily: "Regular" }} dangerouslySetInnerHTML={{ __html: titel }} />
                    </View>

                    {zichtbaarheid == 2 || zichtbaarheid == 3 || zichtbaarheid == 4 ?
                      <Pressable 
                        accessibilityRole='togglebutton' 
                        accessibilityState={ { expanded: adviesZichtbaar[index].open }}
                        accessibilityLabel={adviesZichtbaar[index].open ? "Met deze knop sluit je dit subadvies " : "Met deze knop open je dit subadvies " } 
                        onPress={() => setAdviesZichtbaarHelper(index)} 
                        style={{ position: "absolute", right: 20, top: (mobile ? 12 : 18) }}
                      >
                        <IconSetter family={"AntDesign"} name={adviesZichtbaar[index].open ? "minuscircle" : "pluscircle"} size={24} color={"black"} />
                      </Pressable>
                      :
                      null
                    }

                    { adviesZichtbaar[index].open ?
                      <View accessible={true} onClick={clickHandler} style={[styler.advTekst]}>
                        <div style={{ fontFamily: "Regular", fontSize: 18, lineHeight: '30px' }} dangerouslySetInnerHTML={{ __html: textReplace(tekst, ['']) }} />
                        {tekst.includes('[deelknoppen]') ? <View style={{ flexDirection: 'row', position: 'absolute', bottom: 7.5 }}>
                          {
                            socialButtons.map((data, index) => {
                              return (
                                <SocialButton
                                  key={index}
                                  data={data}
                                  onPress={() => openUrlNewTab(data.url)}
                                  mobile={mobile}
                                />
                              );
                            })
                          }
                        </View> : null}
                      </View>
                    : null }
                    {
                      verfijnen.filter( opt => opt.display ).map((verfijn, index) => {
                        return (
                          <View key={index}>
                            <ButtonVerfijn
                              key={index}
                              index={index}
                              mobile={props.mobile}
                              iconName={verfijn.iconName}
                              iconFamily={verfijn.iconFamily}
                              title={verfijn.tekst}
                              status={verfijn.status}
                              caption='kies'
                              onPress={() => runVerfijn(props, verfijn.code)}
                              borderColor = {verfijn.bordercolor}
                              borderColorHover = {verfijn.bordercolor_hover}  
                              backgroundColor = {verfijn.backgroundcolor}
                              backgroundColorHover = {verfijn.backgroundcolor_hover}
                              color = {verfijn.color}
                              colorHover = {verfijn.color_hover}
                              checkColor = {verfijn.checkcolor}
                              checkColorHover = {verfijn.checkcolor_hover}
                              type = {verfijn.type_id}
                              label = {verfijn.label}
                              class = {verfijn.class}
                              text = {verfijn.text}
                            />
                          </View>
                        )
                      })
                    }
                    {
                    adviesZichtbaar[index].open ?
                      <View style={styler.advToelichting}><div onClick={clickHandler} style={{ fontFamily: "Regular", fontSize: 18, lineHeight: '30px' }} dangerouslySetInnerHTML={{ __html: toelichting }} /></View>
                      :
                      null
                    }
                  </View>
                </View>
              </View>
            );
          })
        }

        {global.settings.show_socials == 1 ?
          <View style={[styler.themeBody, styler.vgMaxBreedte]}>
            <View style={styler.kader}>
              <View style={styler.advies}>
                <View style={styler.advTitelTekst}>
                  <H3 
                    accessible={true} 
                    realHeader={true}
                    level= {"2"}
                    alignSelf={"normal"} 
                    fontFamily={"Regular"}
                  >
                    {socials.titel}
                  </H3>
                </View>
                <View style={styler.advTekst}><div style={{ fontFamily: "Regular", fontSize: 18, lineHeight: '30px' }} dangerouslySetInnerHTML={{ __html: socials.tekst }} /></View>
                {mobile ?
                  <View style={styler.buttonMailMobile}>
                    <TouchableOpacity accessibilityRole='button' onPress={() => setModalVisible(!isModalVisible)}>
                      <View style={{ flexDirection: 'row', alignSelf: 'center', paddingRight: 37 }}>
                        <H4 color={Colors[9]}>{mailButtonCaption}</H4>
                        <FontAwesome5 name="chevron-right" style={{ position: 'absolute', margin: 0, top: '48%', transform: 'translateY(-50%)', right: 18 }} size={(mobile ? 14 : 13)} color={Colors[9]} />
                      </View>
                    </TouchableOpacity>
                  </View>
                  : null
                }
                <View style={{ flexDirection: 'row', alignSelf: (mobile ? 'center' : 'flex-start') }}>
                  {mobile ? <View ref={navigator.userAgent.indexOf("Firefox") != -1 ? null : refMail}></View> :
                    <TouchableOpacity
                      accessibilityRole='button'
                      onPress={() => setModalVisible(!isModalVisible)}
                      style={[styler.buttonMail, { borderWidth: 1, borderColor: (isHoveredMail ? Colors[9] : Colors[0])}]}
                      ref={navigator.userAgent.indexOf("Firefox") != -1 ? null : refMail}
                    >
                      <View style={{ flexDirection: 'row', alignSelf: 'center', paddingRight: 37 }}>
                        <H4 color={Colors[9]}>{mailButtonCaption}</H4>
                        <FontAwesome5 name="chevron-right" style={{ position: 'absolute', margin: 0, top: '48%', transform: 'translateY(-50%)', right: 18 }} size={(mobile ? 14 : 13)} color={Colors[9]} />
                      </View>
                    </TouchableOpacity>
                  }
                  {/* {
                    socialButtons.map((data, index) => {

                      return (
                        <SocialButton
                          key={index}
                          data={data}
                          onPress={() => openUrlNewTab(data.url)}
                          mobile={mobile}
                        />
                      );
                    })
                  } */}
                </View>
                <View style={{ height: 10 }} />
              </View>
            </View>
          </View>
          :
          null
        }

        <Modal 
          animationType={global.settings.overlay_animation || "slide"}
          visible={isModalVisible}
          transparent={true}
          accessible={false}
        >
            <View style={styler.modalOuterView}>
              <View style={styler.modalPosition}>
                <View style={styler.modalView}>
                  <H1 accessible={true} maxWidth='100%' textAlign='left' alignSelf='flex-start' paddingVertical={10} fontSize={27} >{'Advies bewaren of delen'}</H1>
                  <TextInput style={{ borderRadius: 5, borderWidth: 1, padding: 8, width: '50%', marginBottom: 10, borderColor: Colors[11] }}
                    autoCapitalize='none'
                    placeholder={'Jouw e-mailadres'}
                    onChangeText={(newValue) => setEmail(newValue)} 
                  />
                  <View accessible={true} >
                    <P textAlign='left' fontSize={18} lineHeight={30}>{'Je e-mailadres wordt alleen gebruikt om deze informatie te mailen.\nJe e-mailadres wordt niet bewaard.\nMail niet ontvangen? Check dan je spambox.'}</P>
                  </View>
                  <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginTop: 10 }}>
                    <Button arrowIcon={false} color={ emailCheck(email) ? Colors[7] : Colors[8] } square={global.settings.button_border_radius === 0 ? true : false} fontSize={17} fontFamily={'Semibold'} textColor={ emailCheck(email) ? Colors[9] : Colors[11] } height={46} iconOrientation='none' disabled={ !emailCheck(email) } caption={ 'Verstuur' } onPress={() => mailAdvies()} />
                    <Button arrowIcon={false} color={Colors[7]} square={global.settings.button_border_radius === 0 ? true : false} fontSize={17} fontFamily={'Semibold'} textColor={Colors[9]} height={46} iconOrientation='none' caption={'Sluiten'} onPress={() => { setEmail(''); setModalVisible(!isModalVisible)} } />
                  </View>
                  <View style={{ marginVertical: 5 }} />
                </View>
              </View>
            </View>
        </Modal>

          <View style={{ alignSelf: 'center', flexDirection: 'row', marginBottom: 10}}>
            <TouchableOpacity 
              accessibilityRole='button'
              ref={mobile || navigator.userAgent.indexOf("Firefox") != -1 ? null : refVorige} 
              style={[styler.buttons, {backgroundColor: Colors[20], borderWidth: global.settings.border_always_visible  !== 0 ? 1 : isHoveredVorige ? 1 : 0, borderRadius: global.settings.button_border_radius}]} onPress={() => prevScreen()}
            >
              <IconSetter family='FontAwesome5' name='chevron-left' size={21}/>
              <View style={{ width: 10}}/>
              <H4 fontWeight='700' fontSize={18}>{global.settings.text_to_upper_case == 0 ? 'Vorige' : 'VORIGE'}</H4>
            </TouchableOpacity>
            <TouchableOpacity 
              accessibilityRole='button'
              ref={mobile || navigator.userAgent.indexOf("Firefox") != -1 ? null : refOpnieuw} 
              style={[styler.buttons, {backgroundColor: Colors[19] !== '' ? Colors[19] : '#f5f2f2', borderWidth: global.settings.border_always_visible  !== 0 ? 1 : isHoveredOpnieuw ? 1 : 0, borderRadius: global.settings.button_border_radius}]} onPress={() => opnieuwInvullen()}
            >
              <IconSetter family='FontAwesome' name='rotate-left' size={21} color={Colors[19] !== '' ? Colors[16] : Colors[9]}/>
              <View style={{ width: 10}}/>
              <H4 fontWeight='700' fontSize={18} color={Colors[19] !== '' ? Colors[16] : Colors[9]}>{global.settings.text_to_upper_case == 0 ? 'Opnieuw' : 'OPNIEUW'}</H4>
            </TouchableOpacity>
          </View>
      </View>
    </View>
  );
}

// Style
const styler = StyleSheet.create({
  accordeon_eerste: {
    marginLeft: '3%',
    marginRight: '3%',
    backgroundColor: '#e9f5fd',
    marginBottom: 0,
    marginTop: 10,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    borderBottomWidth: 2,
    borderBottomColor:  "white",
  },
  accordeon_midden: {
    marginLeft: '3%',
    marginRight: '3%',
    backgroundColor: '#e9f5fd',
    marginBottom: 0,
    borderBottomWidth: 2,
    borderBottomColor:  "white",
  },
  accordeon_laatste: {
    marginLeft: '3%',
    marginRight: '3%',
    backgroundColor: '#e9f5fd',
    marginBottom: 10,
    borderBottomLeftRadius: 25,
    borderBottomRightRadius: 25,
  },
  accordeon_header: {
    paddingTop: '2%',
    width: '100%',
    backgroundColor: '#e9f5fd',
    marginBottom: 0,
  },
  /* Class vanuit API */
  adv_lightblue:{
    backgroundColor: 'rgb(233, 245, 253)',
    color: 'black'
  },
  adv_blue:{
    backgroundColor: '#3FA1ED',
    color: 'white'
  },
  adv_lightgreen:{
    backgroundColor: '#A8EAB7',
    color: 'black'
  },
  adv_green:{
    backgroundColor: '#76D47B',
    color: 'white'
  },
  adv_darkgreen:{
    backgroundColor: '#054c38',
    color: 'white'    
  },
  adv_orange:{
    backgroundColor: '#ffb036',
    color: 'black'
  },
  adv_red:{
    backgroundColor: '#E55626',
    color: 'white'    
  },
  adv_gray:{
    backgroundColor: '#f7f7f7',
    color: 'black'
  },
  htmlOverlay: {
    backgroundColor: 'rgba(255,255,255, 1)',
    flex: 1,
    alignSelf: 'center',
    zIndex: 99999,
    maxHeight: 580,
    overflowY: 'scroll',
    position: "absolute",
    maxWidth: 1140,
    width: "100%",
    borderRadius: 25,
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)',
    paddingHorizontal: 30,
    paddingVertical: 30,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: Dimensions.get('window').height * 0.05,
    top: 0,
    left: 0,
    right: 0,
  },
  modalOuterView: {
    flex: 1,
    position: 'absolute',
    zIndex: 99,
    width: '100%',
    paddingRight: 20,
    paddingLeft: 20,
    bottom: 140,
  },
  popUpOuterView: {
    flex: 1,
    position: 'absolute',
    zIndex: 99,
    width: '100%',
    paddingRight: 20,
    paddingLeft: 20,
    top: 60,
  },
  modalPosition: {
    flex: 0,
    alignSelf: "center",
    alignItems: "center",
    // marginTop: 80,
    // Styling voor tijdelijke oplossing 
    marginBottom: 80,
  },
  modalView: {
    flexDirection: "column",
    backgroundColor: 'rgba(255, 255, 255, 1)',
    marginTop: 50,
    borderRadius: 25,
    marginVertical: 10,
    padding: 30,
    paddingBottom: 0,
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)',
  },
  popUpMessage: {
    backgroundColor: 'rgba(255, 255, 255, 1)',
    borderRadius: 25,
    padding: 30,
    paddingBottom: 30,
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)',
  },
  themeBody: {
    width: '100%',
    maxWidth: 1140,
    backgroundColor: '#ffffff',
  },
  vgMaxBreedte: {
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  advies: {
    paddingTop: '0%',
    position: 'relative',
    width: '95%',
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    overflow: 'hidden',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50px center',
    backgroundSize: 50,
    marginLeft: '3%',
    marginRight: '3%'
  },
  kader: {
    marginLeft: '3%',
    marginRight: '3%',
    backgroundColor: '#e9f5fd',
    borderRadius: 25,
    marginBottom: 25,
  },
  adviesLeeg: {
    height: 15,
  },
  advToelichting: {
    width: '100%',
  },
  advTekst: {
    clear: 'both',
    width: '100%'
  },
  advTitel: {
    paddingTop: 4,
  },
  advTitelTekst: {
    float: 'left',
    paddingTop: 20,
    paddingLeft: 0,
    marginBottom: 0,
  },
  advTitelTekstInspringen: {
    //paddingLeft: 70,
  },
  buttons: { 
    flexDirection: 'row', 
    paddingVertical: 10, 
    paddingHorizontal: 30, 
    margin:10,
    alignItems: 'center', 
    justifyContent: 'center'
  },
  advBottom: {
    width: "100%",
    marginBottom: 5,
  },
  advAccordion: {
    float: 'left',
    paddingBottom: 4,
    marginTop: -20,
  },
  advSluiten: {
    paddingTop: 25,
    float: 'right',
    height: 50,
    width: 50,
    cursor: 'pointer',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right center',
    backgroundSize: 'contain',
  },
  link: {
    color: "#075fb9",
    cursor: 'pointer',
    marginBottom: 10,
    textDecorationLine: "underline",
    textDecorationStyle: "solid",
    textDecorationColor: "#075fb9",
  },
  buttonMail: {
    width: 300,
    paddingTop: 17,
    paddingBottom: 17,
    borderRadius: 30,
    justifyContent: 'center',
    alignContent: 'center',
    zIndex: 1,
    backgroundColor: 'white',
  },
  buttonMailMobile: {
    width: "80%",
    alignSelf: 'center',
    height: 40,
    marginBottom: 20,
    borderRadius: 30,
    justifyContent: 'center',
    alignContent: 'center',
    zIndex: 1,
    backgroundColor: 'white',
  },
  buttonKies: {
    width: "15%",
    height: 40,
    borderRadius: 30,
    justifyContent: 'center',
    alignContent: 'center',
    zIndex: 1,
    backgroundColor: Colors[3],
  },
  buttonKiesMobile: {
    width: "30%",
    height: 40,
    borderRadius: 30,
    justifyContent: 'center',
    alignContent: 'center',
    zIndex: 1,
    backgroundColor: Colors[3],
  },
  footerOverlay: {
    backgroundColor: 'rgba(255,255,255, 0.9)',
    flex: 1,
    position: 'absolute',
    zIndex: 99999,
    maxWidth: 1140,
    width: "100%",
    borderRadius: 25,
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)',
    paddingHorizontal: 30,
    paddingVertical: 30,
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    bottom: 50,
  },
  scrollView: { 
    backgroundColor: Colors[0],
    maxWidth: 1350,
    minHeight: "calc(100vh - 212px)",
    marginTop: 40,
    marginLeft: 10, 
    marginBottom: 20,
  },
  rowView: { 
    flexDirection: 'row', 
    paddingHorizontal: 40, 
    flexWrap: 'wrap',
  },
  widthView: {
    paddingHorizontal: 40, 
    flexWrap: 'wrap',
    width: "50%"
  },
  objectView: {
    flexDirection: "row",
    width: "100%",
  },
  buttonArea: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  buttonPrev: {
    borderWidth: 1,
    marginRight: 20,
    alignSelf: 'flex-start',
    zIndex: 9999999999999999999999,
  },
  buttonNext: {
    borderWidth: 1,
    zIndex: 9999999999999999999999,
  },
  hover: {
    cursor: 'not-allowed'
  },
  allowHover: {
    cursor: 'pointer'
  },
  rowViewMobile: {
    flexDirection: 'row', 
    paddingHorizontal: 40,
    flexWrap: 'wrap'
  },
  objectViewMobile: {
    flexDirection: "row",
    width: "100%",
    flexWrap: "wrap",
    marginBottom: 15,
  },
  buttonAreaMobile: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginVertical: 10,
    marginHorizontal: 20,
  },
  buttonPrevMobile: {
    width: "42%",
    alignSelf: 'flex-start',
    marginRight: 5,
    zIndex: 1,
    backgroundColor: '#f5f2f2',
  },
  buttonNextMobile: {
    width: "42%",
    marginLeft: 5,
    alignSelf: 'flex-end',
    zIndex: 1,
  },
  scrollViewMobile: { 
    backgroundColor: Colors[0], 
    marginTop: 20,
    paddingBottom: 60,
    minHeight: Dimensions.get('screen').height* 0.5 
  },
  inputViewMobile: {
    width: "100%"
  }
});

const useStyles = CreateResponsiveStyle(
  {},
  {
    // Will apply the size 30 font to large and extra large devices
    [minSize(DEVICE_SIZES.EXTRA_SMALL_DEVICE)]: {
      buttonView: {
        position: 'fixed',
        paddingVertical: 10,
        right: 0,
        bottom: 60,
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 1, 
        borderColor:'#DEDEDE'
      },
      homeButton: {
        height: 45,
        width: 45,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor:'white',
      },
      colThree: {
        width: '100%',
      },
      colTwo: {
        width: '100%'
      },
      colOne: {
        width: '100%'
      },
    },
    [minSize(DEVICE_SIZES.MEDIUM_DEVICE)]: {
      colThree: {
        width: '50%',
      },
      colTwo: {
        width: '50%'
      },
      colOne: {
        width: '100%'
      },
    },
    [minSize(DEVICE_SIZES.LARGE_DEVICE)] : {
      homeButton: {
        height: 45,
        width: 45,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor:'white',
      },
      colThree: {
        width: '33%',
      },
      colTwo: {
        width: '50%'
      },
      colOne: {
        width: '100%'
      },
    },
    [minSize(DEVICE_SIZES.EXTRA_LARGE_DEVICE)] : {
      homeButton: {
        height: 45,
        width: 45,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor:'white',
      },
      colThree: {
        width: '33%',
      },
      colTwo: {
        width: '50%'
      },
      colOne: {
        width: '100%'
      },
    }
  }
)